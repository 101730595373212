import { User } from "../../web/ts/lib/user";

declare var Dropzone;

export class DropzoneBuilder {
    private container: string;
    private dropZone: any;
    private headers: { [key: string]: string } = {};
    private mode: "s3"|"direct";

    public constructor(container: string, mode: "s3"|"direct" = "s3") {
        this.container = container;
        this.mode = mode;
    }

    public get element(): HTMLElement {
        return this.dropZone.element;
    }

    public setAPI(api: string): DropzoneBuilder {
        return this;
    }

    public setExistingImage(url: string): void {
        var mockFile = {
            name: url.replace(/^.+\/([^\/]+)$/, "$1"),
            size: 12345,
            accepted: true,
            status: "success",
            kind: "image"
        };

        this.dropZone.displayExistingFile(mockFile, url, null, "Anonymous", false);

        this.dropZone.element.querySelector(".dz-preview").className += " dz-success";

        this.dropZone.files.push(mockFile);
    }

    public addHeader(header: string, value: string): void {
        this.headers[header] = value;
    }

    public create(init: CallableFunction, accept: CallableFunction, complete: CallableFunction, method: string = "put"): void {
        const options: any = {
            dictDefaultMessage: "Click or drop files here to upload",
            acceptedFiles: "image/*",

            // The URL will be changed for each new file being processing
            url: "/",

            // Since we're going to do a `PUT` upload to S3 directly
            method,

            // Upload one file at a time since we're using the S3 pre-signed URL scenario
            parallelUploads: 1,
            uploadMultiple: false,

            // Content-Type should be included, otherwise you'll get a signature
            // mismatch error from S3. We're going to update this for each file.
            header: "", // 'x-amz-acl': 'public-read'

            headers: this.headers,

            // We're going to process each file manually (see `accept` below)
            autoProcessQueue: false,

            init() {
                init(this);
            },

            // Here we request a signed upload URL when a file being accepted
            accept(file, done) {
                accept(this, file, done);
            },

            complete(file) {
                complete(this, file);
            }
        };

        if (this.mode === "s3") {
            // Hijack the xhr.send since Dropzone always upload file by using formData
            // ref: https://github.com/danialfarid/ng-file-upload/issues/743
            options.sending = (file, xhr) => {
                let _send = xhr.send;

                xhr.send = () => {
                    _send.call(xhr, file);
                };
            };
        }

        this.dropZone = new Dropzone(this.container, options);
    }
}

export function dropzoneBuilder(container: string, galleryAPI: string, token: string = null) {
    const dz = new Dropzone(container, {
        dictDefaultMessage: "Click or drop files here to upload",
        acceptedFiles: "image/*",

        // The URL will be changed for each new file being processing
        url: "/",

        // Since we're going to do a `PUT` upload to S3 directly
        method: "put",

        // Hijack the xhr.send since Dropzone always upload file by using formData
        // ref: https://github.com/danialfarid/ng-file-upload/issues/743
        sending(file, xhr) {
            let _send = xhr.send;

            xhr.send = () => {
                _send.call(xhr, file);
            };
        },

        // Upload one file at a time since we're using the S3 pre-signed URL scenario
        parallelUploads: 1,
        uploadMultiple: false,

        // Content-Type should be included, otherwise you'll get a signature
        // mismatch error from S3. We're going to update this for each file.
        header: "", // 'x-amz-acl': 'public-read'

        // We're going to process each file manually (see `accept` below)
        autoProcessQueue: false,

        // Here we request a signed upload URL when a file being accepted
        accept(file, done) {
            const headers: any = {};

            if (token) {
                headers.authorization = `Bearer ${token}`;
            }

            $.ajax({
                url: galleryAPI,
                type: "post",
                data: { // TODO!: pass http option to dropzoneBuilder
                    name: file.name,
                    type: file.type
                },
                headers,
                dataType: "json"
            })
                .then(response => {
                    dz.options.url = response.url;
                    file.uploadURL = response.url;
                    file.filename = response.filename;

                    done();

                    dz.processFile(file);
                })
                .fail(error => {
                    done("Failed to get an S3 signed upload URL", error);
                });
        }
    });

    return dz;
}
