import { apiBaseURL } from "../../../web/ts/config";

export class Http {
    private subDomain: string;
    private uuid: string;

    constructor(subDomain: string, uuid: string) {
        this.subDomain = subDomain;
        this.uuid = uuid;
    }

    public get<T>(path: string, data: { [key: string]: any } = null): Promise<T> {
        return new Promise((resolve, reject) => {
            $.get(`${apiBaseURL}/web/${path}`, data)
                .done((response: T) => {
                    resolve(response);
                })
                .fail(error => {
                    reject(error);
                });
        });
    }

    public post<T>(path, data: { [key: string]: any }): Promise<T> {
        return new Promise((resolve, reject) => {
            $.post(`${apiBaseURL}/web/${path}`, data)
                .done((response: T) => {
                    resolve(response);
                })
                .fail(error => {
                    reject(error);
                });
        });
    }
}
