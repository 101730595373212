import "./lib/array";
import { getSubDomain, getUuid } from "./lib/utils";
import { Http } from "./lib/http";
import { Gallery } from "./lib/gallery";
import * as config from "../../web/ts/config";

declare const $: any;
const _global = (window /* browser */ || global /* node */) as any;

_global.initPage = () => {
    // TODO: get subdomain and a unique key (to prevent changing domain!)
    const subDomain = getSubDomain(window.location);;
    const uuid = getUuid();
    const http = new Http(subDomain, uuid);

    $.post(`${config.apiBaseURL}/web`, {
        subDomain,
        url: window.location.href
    });

    $("#rsvp-form").submit(function(e) {
        e.preventDefault();

        var data = $("#rsvp-form").serializeObject(),
            successMsg = data.rsvp === "yes" ? "Thanks, we looking forward to seeing you" : "Thanks, we're sorry to hear that you can't make it";

        if (data.name === "") {
            $("#rsvp-form input[name=name]").focus();
        } else if (data.contact === "") {
            $("#rsvp-form input[name=contact]").focus();
        } else {
            $("#rsvp-form").removeClass("failed").addClass("submitting");
            $("#rsvp-form .success").text(successMsg);

            $.post(`${config.apiBaseURL}/web/rsvp`, data, function() { // TODO: URL from config and include origin (subdomain) in data
                $("#rsvp-form").removeClass("submitting").addClass("success");
            }, "json").fail(function() {
                //$("#rsvp-form").removeClass("submitting").addClass("success");
                $("#rsvp-form").removeClass("submitting").addClass("failed");
            });
        }

        return false;
    });

    $("#menu-form").submit(function(e) {
        e.preventDefault();

        var data = $("#menu-form").serializeObject();
        var keys = $("input[name=name], .course-option input")
            .map(function(i, e) {
                return e.name;
            })
            .get()
            .unique();

        for (var i = 0; i < keys.length; i++) {
            var key = keys[i];

            if (!data[key] || (key !== "name" && data[key] === "")) {
                $("input[name=" + key + "]:first").focus();

                return;
            }

            if (!data[key]) {
                $("input[name=" + key + "]:first").focus();

                return;
            }
        }

        $("#menu-form").removeClass("failed").addClass("submitting");

        $.post(`${config.apiBaseURL}/web/menu`, data, function() { // TODO: URL from config and include origin (subdomain) in data
            $("#menu-form").removeClass("submitting").addClass("success");
        }, "json").fail(function() {
            //$("#rsvp-form").removeClass("submitting").addClass("success");
            $("#menu-form").removeClass("submitting").addClass("failed");
        });

        return false;
    });

    if ($("#gallery").length) {
        const gallery = new Gallery(http, config);

        gallery.init();
    }
};
