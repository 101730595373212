import { dropzoneBuilder } from "../../../common/ts/dropzoneBuilder";
import { Http } from "./http";
import JQuery from "jquery";

declare var Dropzone;

interface IGalleryImage {
    thumb: string;
    full: string;
}

interface IGalleryResponse {
    baseURL: string;
    images: IGalleryImage[];
}

export class Gallery {
    private http: Http;
    private galleryElement: JQuery;
    private images: IGalleryImage[] = [];
    private index: number;
    private config: any;

    constructor(http: Http, config: any) {
        this.http = http;
        this.config = config;
    }

    public init(): void {
        this.galleryElement = $("#gallery");

        const isHome = this.galleryElement.data("mode") === "home";
        const fullURL = String(window.location.search).match(/(?<=img=)[^$&]+/);
        let options = {};

        if (isHome) {
            options = {
                limit: 12,
                random: true
            };
        }

        if (fullURL && fullURL[0]) {
            const url = encodeURI(decodeURIComponent(fullURL[0]));

            this.index = this.getImageIndex(url);
            this.showImage(url);
        }

        this.loadGallery();

        $("#gallery-unlock-btn").on("click", () => {
            this.loadGallery();
        });

        $("#gallery-popup .close").on("click", () => {
            $("#gallery-popup").removeClass("in show");
        });

        $("#gallery-popup img").on("load", () => {
            const imgHeight = $("#gallery-popup img").height();
            const popupHeight = $("#gallery-popup").height();

            $(".modal-body").css("padding-top", imgHeight >= popupHeight ? 0 : ((popupHeight - imgHeight) / 2));
        });

        $(".gallery-btn").on("click", (event) => {
            this.showNextImage(event.currentTarget.className.indexOf("prev") > -1 ? -1 : 1);
        });


        $(document).on("keyup", (e) => {
            const img = $("#gallery-popup img").attr("src");

            switch (e.key) {
                case "ArrowLeft":
                    this.showNextImage(-1);
                    break;
                case "ArrowRight":
                    this.showNextImage(1);
                    break;
                case "Escape":
                    $("#gallery-popup").removeClass("in show");

                    break;
            }
        });

        // TODO: add dropzonejs
        // TODO: get gallery
        // TODO: configure endless scroll if enabled
        // TODO: populate gallery with images (should be a separate page? with just a selection on home page?)
        // TODO: initialize gallery upload if enabled (with option to tag photos)
        // TODO: full screen view
    }

    loadGallery() {
        const isHome = this.galleryElement.data("mode") === "home";
        const fullURL = String(window.location.search).match(/(?<=img=)[^$&]+/);
        const passwordKey = "gallery-password";
        let options: any = {};

        if (isHome) {
            options = {
                limit: 12,
                random: true
            };
        }

        options.password = sessionStorage.getItem(passwordKey) || $("#gallery-password").val(); // TODO: auth header

        this.http
            .get<IGalleryResponse>("gallery", options)
            .then((response: IGalleryResponse) => {
                $("#gallery-lock").hide();

                sessionStorage.setItem(passwordKey, options.password);

                if (response.images.length === 0) {
                    emptyGallery(this.galleryElement);

                    return;
                }

                this.images = response.images;

                for (const image of this.images) {
                    const element = $(`<a class="col col-xs-3" target="_blank"><img src="/gallery/${image.thumb}"/></a>`);

                    if (this.galleryElement.data("mode") === "home") {
                        element.attr("href", `/gallery.html?img=${encodeURIComponent(decodeURI(image.full))}`);
                    } else {
                        element.data("full", image.full).on("click", (event) => {
                            const url = $(event.currentTarget).data("full");

                            this.index = $(event.currentTarget).index(); //this.getImageIndex(url);
                            this.showImage(url);
                        });
                    }

                    this.galleryElement.append(element);
                }

                this.initDropzone();
            })
            .catch(response => {
                if (response.status === 403) {
                    $("#gallery-lock").show();
                } else {
                    emptyGallery(this.galleryElement);
                }
            });
    }

    private showImage(url): void {
        $("#gallery-popup").addClass("show in");
        $("#gallery-popup img").attr("src", `/gallery/${url}`);
    }

    private showNextImage(direction): void {
        if (this.images[this.index + direction]) {
            this.index += direction;
        } else if (direction < 0) {
            this.index = this.images.length - 1;
        } else {
            this.index = 0;
        }

        const url = this.images[this.index].full;

        this.showImage(url);

        // TODO: update URL
        history.pushState(url, null, `/gallery.html?img=${encodeURIComponent(url)}`);
    }

    private getImageIndex(url): number {
        for (let index = 0;index < this.images.length; index++) {
            if (this.images[index].full === url) {
                return index;
            }
        }

        return 0;
    }

    private initDropzone(): void {
        $.getScript(`${this.config.cdnBaseURL}/dropzone.min.js`, () => {
            const dz = dropzoneBuilder("#gallery-upload", `${this.config.apiBaseURL}/web/gallery`);

            $("#gallery-upload").show();
        });
        $("<link>")
            .appendTo("head")
            .attr({
                type: "text/css",
                rel: "stylesheet",
                href: `${this.config.cdnBaseURL}/dropzone.min.css`
            });
    }
}

function emptyGallery(gallery): void {
    gallery.text("Coming soon!");
}
