interface Array<T> {
    contains(value: any): boolean;
    unique(): Array<T>;
}

Array.prototype.contains = function(value: any): boolean {
    for (var i = 0; i < this.length; i++) {
        if (this[i] === value) return true;
    }

    return false;
};

Array.prototype.unique = function() {
    var arr = [];

    for (var i = 0; i < this.length; i++) {
        if (!arr.contains(this[i])) {
            arr.push(this[i]);
        }
    }

    return arr;
};
